/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

:root,
:root[mode=ios],
:root[mode=md] {
    --ion-font-family: "Roboto" !important;
    font-family: "Roboto" !important;
}

* {
    font-family: 'Roboto' !important;
    --ion-font-family: 'Roboto' !important;

}

.sc-ion-buttons-md-h {
    display: block !important;
}

.header {
    margin: 1rem;
    padding-top: 3.1px;
    background: white;
}

.header-banner {
    width: 3.3rem;
    margin: 1rem;
    padding-top: 0.1px;
}

.banner {
    object-fit: contain;
}

.footer-buttons {
    display: flex;
    justify-content: center;
    height: 3rem;
}

.footer-img {
    font-size: 35px;
    height: 30px;
}

.sidebar-header {
    height: 5.45rem
}

ion-header {
    ion-toolbar {
        --color: #ffffff;
        --background: #008339;
        --padding-top: 0;
        --padding-start: 0;
        height: 68px;

        ion-buttons {
            height: 68px;
        }
    }
}

ion-title {
    display: flex;
    justify-content: center;
}

ion-menu-button {
    background-color: #A2C617;
    --color: #fff;
    // height: 6rem;
    height: 100%;
    width: 6rem;
    // position: absolute;
    // left: -4px;
    // top: -47px;
    font-size: 50px !important;
}

ion-content {
    // position: absolute;
    // top: 5.4rem;
    --padding-top: 5.4rem !important;
    // --padding-bottom: 4rem!important;

}

ion-button:not(.footer-button, .switch-button) {
    outline: none !important;
    --background: #008339;
    font-weight: 400;
    z-index: 1;
    --background-activated: white;
}

.footer-button {
    margin: 0;
    height: 100% !important;
    --background: white !important;
    --box-shadow: none;
    --background-activated: white;

}

.footer-button:focus {
    outline: none !important;
}

.margin-right {
    margin-right: 0.5rem;
}

ion-datetime::after {
    padding-right: 4rem !important;
}

.p-1 {
    padding: 10px;
    color: #646363;
    font-weight: 500;

    h2 {
        font-size: 26px;
    }

    p {
        font-size: 18px;
    }

    // margin-left: 2rem;
}

.benefit {
    justify-content: center;
    display: flex;
}

.verticalAlign {
    padding-top: 2px;
    margin-left: 18px;
}

.row {
    display: flex;
}

.green_bubble {
    position: absolute;
    overflow: hidden;
    right: -20%;
    bottom: 64%;
    width: 45%;
    transform: rotate(347deg);
}

.grey_bubble {
    position: absolute;
    overflow: hidden;
    right: -56%;
    bottom: -26%;
    width: 100%;
    transform: rotate(121deg);
}

.green_bubble_compare {
    position: absolute;
    overflow: hidden;
    right: -77%;
    bottom: 31%;
}

.green_gray_bubble {
    position: absolute;
    overflow: hidden;
    left: -205px;
    top: -157px;
    width: 78.32%;
}

.top-logo {
    position: absolute;
    right: 4rem;
    top: 8rem;
}

.top-logo-2020 {
    position: absolute;
    right: 1rem;
    top: 7rem;
    width: 10rem;
}

.product-selection-image {
    top: 9rem;
    position: fixed;
    left: 0rem;
    width: 48rem;
    min-width: 48rem;
}

.product-selection {
    position: absolute;
    display: flex;
    justify-content: center;
}

.signature {
    margin-top: 5rem;
    justify-content: center;
}

.signature-modal {
    padding: 1rem;
}

.signature-button {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
}

.slider-background {
    top: 13rem;
    // position: fixed;
    left: 8rem;
    width: 45rem;
    min-width: 45rem;
}

.customer-form {
    height: 3rem;
    border: 1px solid lightgray;
    padding-left: 25px;
    width: 100%;
}

.customer-container {
    width: 31rem;
}

// ion-router-outlet{
//         margin-bottom: 56px;
// }
.margin {
    margin-bottom: 56px;
}

.no-margin {
    margin: 0px;
}

ion-datetime {
    border: 1px solid lightgray;
    height: 3rem;
    padding-left: 25px !important;
    color: rgb(110, 110, 110);
    line-height: 1.6rem;

    &::after {
        content: "";
        width: 10px;
        height: 22px;
        top: 1.5px;
        background: url(./assets/images/icon-date.svg);
        background-position: center center;
        background-size: contain;
        background-repeat: no-repeat;
        padding-right: 15px;
        padding-left: 6px;
        display: block;
        position: relative;
    }
}

.picker-opt.picker-opt-selected {
    color: #008339 !important;
}

.picker-button {
    color: #008339 !important;

}

.submit {
    color: white;
    --background: #008339;
    width: 15rem;
    margin-top: 4rem;
}

.form-submit-row {
    display: flex;
    justify-content: center;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 30px;
    margin-bottom: 35px;
    border-top: 1px solid lightgray;
}

.password-text {
    line-height: 15px;
}

.product-button1 {
    --background: #008339;
    // position: fixed;
    // left: 10.4rem;
    // top: 36rem;
    z-index: 1;
}

.product-button2 {
    --background: #008339;
    // position: fixed;
    // left: 28rem;
    // top: 36rem;
    z-index: 1;
}

.product-button3 {
    --background: #008339;
    position: fixed;
    left: 45.5rem;
    top: 36rem;
    z-index: 1;
}

.calc-container {
    width: 570px !important;
    margin: 10px auto 0;
    border-radius: 5px;
    box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.15);

    .calculator-header {
        // position: absolute;
        // top: 16.5rem;
        padding: 20px;
        color: #646363;

        h3 {
            font-size: 20px;
            text-transform: uppercase;
        }

        span {
            font-size: 14px;
            opacity: 0.58;
        }
    }

    .calculator-input {
        // position: absolute;
        // top: 24rem;
        // width: 37rem;
        background-color: #F6F6F6;
        padding: 26px 50px;

        ion-col {
            padding: 0;
        }

        input {

            background-color: #F6F6F6;
            margin-left: 9px;
        }

        .kwh-input {
            &[type=number]:before {
                content: "kWh";
            }
        }
    }

    .slider {
        // position: absolute;
        // top: 31rem;
        // width: 30rem;
        padding: 45px 80px 60px 45px;

        .label1 {
            color: #cecece;
        }

        .label2 {
            margin-left: 4rem;
            color: #cecece;
        }

        .label3 {
            margin-left: 3.3rem;
            color: #cecece;
        }

        .label4 {
            margin-left: 3rem;
            overflow: visible;
            color: #cecece;
        }

        .labels {
            width: 35rem;
        }

        .label1gas {
            color: #cecece;
        }

        .label2gas {
            margin-left: 7rem;
            color: #cecece;
        }

        .label3gas {
            margin-left: 4rem;
            color: #cecece;
        }

        .label4gas {
            margin-left: 4.5rem;
            overflow: visible;
            color: #cecece;
        }

        .active-label {
            color: #646363 !important;
        }
    }

    .calc-input {
        width: 8rem;
    }

    .calculator-button {
        position: absolute;
        width: 14rem;
        height: 3rem;
        left: 50%;
        transform: translate(-50%, -50%);
        margin: 0;
        --background: #008339;
        font-size: 15px;
    }
}

.container {
    display: flex;
    justify-content: center;
}

.notices {
    border: 1px solid lightgray;
    height: 9rem;
}

[aria-checked="true"].sc-ion-alert-md .alert-radio-icon.sc-ion-alert-md {
    border-color: #008339;
}

// checkbox or radio button color
.alert-radio-inner.sc-ion-alert-md {
    background-color: #008339 !important;
}

// Button color
.alert-button-inner.sc-ion-alert-md {
    color: #008339;
}

.currentUser {
    position: absolute;
    right: 25px;
    top: 30px;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 13rem;
}

// ion-grid{
//     margin-left: 2rem
// }
img {
    vertical-align: unset;
}

.product-item-selection-image {
    top: -6rem;
    height: 43rem;
    position: absolute;
    left: -1rem;
    width: 48rem;
    min-width: 48rem;
}

.glossar-icon {
    padding-bottom: 2px;
    margin-right: 1rem;
}

/* Glossar */
.grey_bubble_background {
    background: url(./assets/images/bubble-grey.svg) no-repeat 41rem -4rem
}

.glossar-content {
    padding: 40px 33px 0;

    .glossar-btn {
        padding-left: 29px;
    }

    .glossar-btn:before {
        content: "";
        background-image: url(./assets/icon/icon-page-back.svg);
        position: absolute;
        width: 9px;
        height: 13.5px;
    }

    .glossar-tablist {
        display: flex;
        list-style: none;
        border-bottom: 1px solid #DCDCDC;
        padding-inline-start: 0px;
        margin-bottom: 66px;

        .glossar-tablink {
            font-size: 15px;
            margin-right: 70px;
            margin-bottom: -4px;
            padding-bottom: 12px;
            border-bottom: 7px solid transparent;

            a {
                text-decoration: none !important;
                color: #646363 !important;
            }
        }

        .glossar-tablink.active {
            border-bottom: 7px solid #A2C617;
            font-weight: 500;
        }

        .glossar-tablink:not(.active) {
            opacity: 0.5;
        }
    }

    .glossar-tab-content {
        ion-card {
            border-radius: 0;

            ion-card-header {
                cursor: pointer;
            }
        }

        .glossar-arrow {
            content: url(./assets/icon/icon-not-expanded.svg);
            position: absolute;
            right: 1.063rem;
            top: 50%;
            transform: translateY(-50%) rotate(270deg);
        }
    }

    .glossar-detail {
        ion-card {
            box-shadow: none;

            ion-card-header {
                cursor: pointer;
                padding: 5px 0 20px;

                h3 {
                    color: #000000;
                    font-size: 24px;
                    margin: 0;
                }
            }

            ion-card-content {
                p {
                    max-width: 850px;
                    font-size: 15px;
                }
            }
        }
    }

    ion-card-content {
        padding: 0;
    }
}

.nav-tabs .nav-link {
    border: none !important;
    border-top-left-radius: 0rem !important;
    border-top-right-radius: 0rem !important;
    padding-left: 0px;
    padding-right: 0px;
    margin-left: 15px;
    margin-right: 15px;
}

.nav-link.active {
    color: #495057;
    border-bottom: 5px solid yellowgreen !important;
    /* border-color: #dee2e6 #dee2e6 #fff; */
}

.nav-tabs {
    border-bottom: 1px solid #dee2e6;
    width: 55rem;
    margin-top: 3rem;
}

.tüv-logo {
    position: absolute;
    right: 2rem;
    top: 7rem;
}

#canvas {
    border-bottom: 1px solid black;
}

#canvas2 {
    border-bottom: 1px solid black;
}

#background-content {
    --background: #383B40 !important;
}

.list-md .item-lines-none {
    --border-width: 0;
    --inner-border-width: 0;
}

.page-content {
    height: 100%;
    padding: 1rem;
}

// ion-content {
//     --overflow: hidden;
// }
.inner-scroll {
    overflow: inherit;
}

.price-comparison {
    .p-1 {
        color: #646363;
        font-weight: 500;
        margin-bottom: 70px;

        h2 {
            font-size: 26px;
        }

        p {
            font-size: 18px;
            margin: 0;
        }
    }

    .compare-content {
        color: #646363;
        background-color: rgba(220, 220, 220, 0.27);
        padding: 20px 18px 18px;
        margin: 0 -13px;

        .compare-header {
            margin-bottom: 20px;
            display: flex;

            h3 {
                color: #008339;
                font-size: 24px;
                font-weight: 500;
                margin: 0 20px 0 0;
            }

            span {
                font-size: 15px;
                display: flex;
                align-items: flex-end;
            }
        }

        .compare-table {
            margin: 0;
            padding: 0;

            ion-row {
                ion-col:first-child {
                    width: 177px;
                    max-width: 177px;
                }

                ion-col:nth-child(2) {
                    width: 277px;
                    max-width: 277px;
                }
            }

            ion-col {
                padding: 0;
            }

            .green-header {
                color: #008339;

                ion-label {
                    padding-bottom: 4px;
                    border-bottom: 2px solid #008339;
                }
            }

            .compare-table-header {
                font-weight: 500;
                color: #000000;
                margin-bottom: 18.5px;
                padding-bottom: 30px;
                border-bottom: 1px solid #CCCCCC;

                .tiny-header {
                    font-size: 12px;
                }
            }

            .compare-table-row {
                padding-bottom: 18px;
            }

            .compare-table-row.row-border {
                border-bottom: 1px solid #CCCCCC;
                margin-bottom: 18px;
            }

            .compare-table-footer {
                font-weight: 500;

                ion-col:not(:last-child) {
                    font-size: 15px;
                    color: #000000;
                }

                ion-col:last-child {
                    margin-top: -4px;
                    font-size: 20px;
                    color: #008339;
                }
            }
        }
    }

    .compare-footer {
        background-color: #ffffff;
        color: #ffffff;
        height: 90px;
        margin: 0 -13px;

        &>* {
            height: 100%;
            background-color: #008339;
            font-size: 15px;
            display: flex;
            align-items: center;
        }

        .compare-green-left {
            position: relative;
            float: left;
            padding: 18px;
            width: 650px;
            max-width: 650px;

            .division-price {
                position: absolute;
                right: 50px;
                font-size: 36px;
                transform: rotate(-12deg);
            }
        }

        .compare-green-right {
            float: right;
            width: 313px;
            max-width: 313px;
            justify-content: center;

            span {
                text-align: center;
                margin-right: 8px;
            }
        }

        p {
            margin: 0;
        }
    }
}

.bonus-modal {
    --width: 100%;
    --height: 100%;
    width: 100%;
    height: 100%;

    .ion-text-center {
        z-index: 100;
        position: relative;
        height: 100%;

        .bonus-amount {
            color: #fff;
            position: absolute;
            left: 50px;
            top: 90px;

            .bonus-head {
                margin: 0;
                font-size: 107px;
                line-height: 123px;
                text-align: left;
                font-weight: bold;
            }

            .bonus-desc {
                padding-left: 64px;
                font-size: 36px;
                line-height: 42px;
                margin-top: -7px;
            }
        }

        .bonus-bottom {
            position: absolute;
            bottom: 60px;
            left: 0;
            width: 100%;
            font-size: 15px;

            .bonus-save {
                margin-bottom: 40px;
                text-transform: uppercase;
            }

            .bonus-bottom-text {
                line-height: 22px;
                margin-bottom: 0;
            }
        }
    }
}

.calc-modal {
    --overflow: visible;
    // --height: 460px;
    --height: 530px;
    --width: 750px;
    --border-radius: 5px;

    ion-content {
        --padding-top: 30px !important;

        .compare-header {
            color: #646363;
        }
    }

    .title-label {
        padding-left: 20px !important;
    }

    ion-col {
        padding: 0;

        ion-label {
            // line-height: 44px;
            padding: 5px;
        }

        .input-col {
            --padding-top: 0 !important;
            --padding-bottom: 0 !important;
            --padding-start: 28px !important;
            --padding-end: 0 !important;
            float: right;
            border: 1px solid #aaa;
        }
    }

    .compare-input {
        background: #F6F6F6;
        padding: 25px 25px;

        .first-input {
            padding: 25px 40px 25px 5px;
        }

        ion-col {
            display: flex;
            padding: 0 20px;
        }
    }

    .input-wrapper {
        // display: flex;
        margin: 40px 0;

        .input-table {
            margin: 20px 0 0;
            padding: 0;
        }

        .input-table:first-child {
            border-right: 1px solid #DCDCDC;
        }

    }

    .compare-button-wrapper {
        display: flex;

        .compare-button {
            position: absolute;
            bottom: 25px;
            left: 50%;
            margin: 0;
            transform: translateX(-50%);
            --background: #008339;
            border-radius: 5px;
        }
    }

}

.comparison-popup {
    ion-fab-button {
        width: 70px;
        height: 70px;
        --background: transparent;
        background: transparent;
    }

    ion-icon {
        width: 100%;
        height: 100%;

        &[class*="icon-"] {
            mask-size: contain;
            mask-repeat: no-repeat;
            background: transparent;
        }
    }
}

.hover {
    cursor: pointer;
}

.addCustomer {
    display: flex;
    min-width: 12rem;
}

.align-center {
    display: flex;
    justify-content: center;
}
.max-height{
    height: 100%;
}

.column {
    display: flex;
    flex-direction: column;
}

.footer-text {
    font-size: 10px;
    color: #000;
}

.invisible {
    visibility: hidden;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

.margin-left {
    margin-left: 2rem;
}

.smaller {
    font-size: 10px;
}

.benefits-modal {
    --width: 35rem;
    --height: 15rem;
}

.menuToggle:hover {
    --background-color: #383B40 !important;
    --background: #383B40 !important;
    --background-hover: #383B40 !important;
    --background-activated: #383B40 !important;
    --background-focused: #383B40 !important;
}

input {
    background-color: white;
}

.small-br {
    display: block;
}

.svg-overflow {
    overflow: inherit !important;
}
::ng-deep 
.alert-radio-group {
         width: 315px!important;
         --width: 315px!important;

}
.cordova-camera-capture button{
    content: "";
    background: url(/assets/icons/circle.svg) no-repeat;
    color: transparent;
    left: calc(50% - 48px);
    bottom: 24px;
    width: 75px;
    height: 75px;
    background-size: contain;
    position: absolute;
}
.cordova-camera-capture{
    background: #000000;
}